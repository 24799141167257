import 'devise.scss';
import("bootstrap")
import $ from "jquery"
import debounce from "../src/lib/debounce"

$(() => {
  $('#registration_nickname').each((_index, el) => {
    const $nickname = $(el)
    const $form = $nickname.closest('form')
    const $submit = $form.find("input[type=submit]:first")
    const $feedback = $('<div></div>').hide().insertAfter($nickname)
    const token = $("#registration_token").attr('value')


    const disableSubmit = () => {
      $submit.attr('disabled', true);
    }

    const enableSubmit = () => {
      $submit.attr('disabled', false);
    }

    const markValid = (message) => {
      $nickname.addClass('is-valid').removeClass('is-invalid')
      $feedback
        .addClass('valid-feedback')
        .removeClass('invalid-feedback')
        .text(message)
        .show()
      enableSubmit()
    }

    const markInvalid = (message) => {
      $nickname.addClass('is-invalid').removeClass('is-valid')
      $feedback
        .removeClass('valid-feedback')
        .addClass('invalid-feedback')
        .text(message)
        .show()
      disableSubmit()
    }

    const checkIfTaken = (event) => {
      let nickname = event.target.value
      $.getJSON("/registrieren/express/nickname", {"q": nickname, "token": token})
        .then(({valid, message}) => {
          if (valid) {
            markValid(message)
          } else {
            markInvalid(message)
          }
        })
    }
    $nickname.on( 'keyup', debounce( 250, checkIfTaken))
    $nickname.on( 'keyup', disableSubmit)

    if ($nickname.attr('value').startsWith('KT-')) {
      $nickname.select()
    }
  })

  // Automatic sign in
  $('#oauth-signin').click()
})
